const MailchimpModule = (function(w, $) {
  const settings = {
    $form: $('form')
  }

  const init = () => {
    const _s = settings;
    console.log('initialize Mailchimp');

    _s.$form.each((index, el) => initForm($(el)));
  }

  const initForm = ($form) => {
    const _s = settings;
    const mc = {
      $form: $form,
      status: 'open',
    }

    mc.$form.on('submit', (e) => handleNewsletterSubmit(e, mc));
  }

  const handleNewsletterSubmit = (e, mc) => {
    e.preventDefault();
    const email = mc.$form.find('#mc-email').val();
    const input = mc.$form.find('input');
    const submitBtnText = mc.$form.find('button[type="submit"] span');
    const messageDiv = mc.$form.find('.message');

    mc.$form.find('.message').html('We hate spam and promise to keep your email address safe.');

    if (email === '') {
      messageDiv.html('Please provide a valid email address');

      return false;
    }

    const payload = {
      'action': 'mailchimp_subscribe',
      'email': email,
    }

    submitBtnText.html('<span>Submitting...</span>');

    fetch('./mailchimp.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(resp => resp.json())
      .then(resp => {
        const msg = resp.msg;

        if (resp.result === 'success') {
          messageDiv.html(msg);
          input.val('');
        }

        if (resp.result === 'error') {
          messageDiv.html(msg);
        }

        submitBtnText.html('<span>Get Notified</span>');
      })
      .catch(error => {
        console.error(error);
        messageDiv.html('Hmm, something went wrong. Try again, if you\'re brave!');
        submitBtnText.html('<span>Get Notified</span>');
      });

    return false;
  }

  return { init }
})(window, jQuery);
