const NewModule = (function(w, $) {
    const settings = {};

    // initialize Module
    const init = () => {
      const _s = settings;
      console.log('initialize New Module');

      bindUIActions(_s);
    };

    const bindUIActions = (_s) => {}

    return { init };
})(window, jQuery)
